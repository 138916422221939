<template>
  <div class="page-main course-page">
    <div class="job-box">
      <div v-if="!collapsed" class="job-row flex">
        <div class="job-box-title">工种</div>
        <div class="job-box-select-box flex">
          <template v-for="(item, index) in jobList" :key="index">
            <div
              :class="{
                'job-first-item': true,
                'job-active': item.job_id == selectedJob.job_id,
              }"
              @click="
                () => {
                  selectedJob = item;
                  selectedSecond = 'all';
                }
              "
            >
              {{ item.job_name }}
            </div>
          </template>
        </div>
      </div>
      <div v-if="!collapsed" class="job-row flex">
        <div class="job-box-title">等级</div>
        <div class="job-box-select-box flex">
          <div
            :class="{
              'job-first-item': true,
              'job-active': selectedSecond == 'all',
            }"
            @click="
              () => {
                selectedSecond = 'all';
              }
            "
          >
            全部
          </div>
          <template v-for="(item, index) in selectedJob.children" :key="index">
            <div
              :class="{
                'job-first-item': true,
                'job-active': item.job_id == selectedSecond.job_id,
              }"
              @click="
                () => {
                  selectedSecond = item;
                }
              "
            >
              {{ item.job_name }}
            </div>
          </template>
        </div>
      </div>
      <div v-if="!collapsed" class="job-row job-row-last flex">
        <div class="job-box-title">热门</div>
        <div class="job-box-select-box">
          <a-switch v-model:checked="isHot" />
        </div>
      </div>
      <div class="select-job-title flex-between">
        <div class="select-job-title-text">
          {{ selectedJob.job_name
          }}{{
            selectedSecond == "all"
              ? " - 全部"
              : selectedSecond.job_name
              ? ` - ${selectedSecond.job_name}`
              : ""
          }}
          <a-tag v-if="isHot" color="red">
            <fire-two-tone two-tone-color="#e72929" /> 热门
          </a-tag>
        </div>
        <div
          class="title-tool"
          v-if="!collapsed"
          @click="
            () => {
              collapsed = !collapsed;
            }
          "
        >
          收起 <up-outlined />
        </div>
        <div
          class="title-tool"
          v-else
          @click="
            () => {
              collapsed = !collapsed;
            }
          "
        >
          展开 <down-outlined />
        </div>
      </div>
    </div>
    <div class="course-box">
      <a-spin :spinning="loading">
        <div class="course-box-main">
          <a-empty
            v-if="courseList.length == 0"
            description="暂无课程"
          ></a-empty>
          <template v-for="(item, index) in courseList" :key="index">
            <div class="course-item" @click="goToCourse(item)">
              <img :src="item.course_image" class="course-poster" />
              <div class="course-name">{{ item.course_name }}</div>
              <div class="course-view">
                <eye-outlined /> {{ item.course_view_num }}次播放
              </div>
            </div>
          </template>
        </div>
        <a-pagination
          v-model:current="page"
          v-model:pageSize="limit"
          :defaultPageSize="16"
          :hideOnSinglePage="true"
          show-size-changer
          :total="total"
          :pageSizeOptions="['16', '24', '40', '120']"
          @showSizeChange="onShowSizeChange"
        />
      </a-spin>
    </div>
    <div v-if="user_id" class="menu-icon" @click="goToMine()">
      <div>我 的</div>
      <div>课 程</div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import {
  EyeOutlined,
  DownOutlined,
  UpOutlined,
  FireTwoTone,
} from "@ant-design/icons-vue";
import { getCourseList, getJobList, getUserCourseList } from "@/api/main.js";
import { message } from "ant-design-vue";
import { isEqual } from "lodash-es";
import url from "@/utils/url.js";
import { getUserId, getUserVip, getStudentData } from "@/utils/storeData.js";
import { encode } from "js-base64";

export default defineComponent({
  name: "CourseIndex",
  components: { EyeOutlined, DownOutlined, UpOutlined, FireTwoTone },
  setup() {
    const pageData = reactive({
      courseList: [],
      jobList: [],
      isHot: false,
      selectedJob: {},
      selectedSecond: "all",
      collapsed: false,
      page: 1,
      limit: 16,
      searchKey: null,
      total: 0,
      loading: false,
      user_id: computed(() => getUserId()),
      user_isvip: computed(() => getUserVip()).value,
    });
    const getCourseListFun = () => {
      pageData.loading = true;
      let data = {
        page: pageData.page,
        limit: pageData.limit,
        first_job_id: pageData.selectedJob?.job_id || null,
        second_job_id: pageData.selectedSecond?.job_id || null,
        course_ishot: pageData.isHot ? 1 : 0,
        search_key: pageData.searchKey,
        user_id: pageData.user_id,
      };
      if (pageData.user_isvip == 1) {
        getCourseList(data)
          .then((res) => {
            if (res.code == 200) {
              // console.log(res.data);
              pageData.courseList = res.data.course;
              pageData.total = res.count;
            } else {
              message.error(res.msg);
            }
          })
          .catch((res) => {
            console.log(res);
          })
          .finally(() => {
            pageData.loading = false;
          });
      } else {
        getUserCourseList(data)
          .then((res) => {
            if (res.code == 200) {
              // console.log(res.data);
              pageData.courseList = res.data.course;
              pageData.total = res.count;
            } else {
              message.error(res.msg);
            }
          })
          .catch((res) => {
            console.log(res);
          })
          .finally(() => {
            pageData.loading = false;
          });
      }
    };

    const getJobListFun = () => {
      if (pageData.user_isvip == 1) {
        getJobList()
          .then((res) => {
            if (res.code == 200) {
              pageData.jobList = res.data.job;
              if (res.data.job.length > 0) {
                pageData.selectedJob = res.data.job[0];
                pageData.selectedSecond = "all";
              }
            } else {
              message.error(res.msg);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        getStudentData().then((res) => {
          let job = [];
          res.map((i) => {
            let j = {};
            Object.assign(j, i.first_job);
            j["children"] = [];
            j["children"].push(i.second_job);
            job.push(j);
          });
          pageData.jobList = job;
          if (res.length > 0) {
            pageData.selectedJob = job[0];
            pageData.selectedSecond = "all";
          }
        });
      }
    };
    getJobListFun();

    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };

    const goToCourse = (_course) => {
      url.navigateTo("/course/course", {
        t: encode(`${_course.course_id},${_course?.student_id}`),
      });
    };

    watch(
      [
        () => pageData.selectedJob,
        () => pageData.selectedSecond,
        () => pageData.isHot,
        () => pageData.page,
        () => pageData.limit,
      ],
      (newValue, oldValue) => {
        if (!isEqual(newValue, oldValue)) {
          getCourseListFun();
        }
      }
    );

    const goToMine = () => {
      url.navigateTo("/mine", { type: "course" });
    };

    return {
      ...toRefs(pageData),
      onShowSizeChange,
      goToCourse,
      goToMine,
    };
  },
});
</script>

<style lang="less" scoped>
.course-page {
  height: 100%;
  position: relative;
  .select-job-title {
    font-size: 18px;
    font-weight: 700;
    // margin-bottom: 20px;
    position: relative;
    padding-left: 16px;
    // padding-top: 12px;
    align-items: center;
    &-text {
      display: flex;
      align-items: center;
      .ant-tag {
        margin-left: 12px;
      }
    }
    .title-tool {
      font-size: 14px;
      font-weight: 400;
      color: #7e8c8d;
    }
    .title-tool:hover {
      color: #1890ff;
      cursor: pointer;
    }
  }
  // .select-job-title::before {
  //   content: "";
  //   height: 100%;
  //   width: 4px;
  //   border-radius: 2px;
  //   background-color: #1890ff;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
  .job-box {
    background-color: #fff;
    padding: 30px 20px;
    margin-bottom: 40px;
    box-shadow: 0 6px 10px 0 #5f656910;
    border-radius: 0 0 8px 8px;
    .job-row {
      // height: 30px;
      margin-bottom: 12px;
      align-items: flex-start;
      .job-box-title {
        font-size: 16px;
        margin-right: 20px;
        flex: 0 0 auto;
        font-weight: 700;
      }
      .job-box-select-box {
        flex-wrap: wrap;
        .job-first-item {
          padding: 0 12px;
          border-radius: 12px;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          // background-color: #97cdff2b;
          // border: 1px solid #1890ff;
          white-space: nowrap;
          margin-right: 12px;
          color: #676767;
          margin-bottom: 12px;
          cursor: pointer;
        }
        .job-first-item:hover {
          box-shadow: 0 2px 10px 0 #1890ff60;
        }
        .job-first-item:last-child {
          margin-right: 0px;
        }
        .job-active {
          // color: #fff;
          // background-color: #1890ff;
          color: #1890ff;
          font-weight: 700;
          background-color: #97cdff2b;
        }
      }
    }
    .job-row-last {
      margin-bottom: 32px;
    }
  }
  .course-box {
    width: 100%;
    margin-bottom: 30px;
    .course-box-main {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px;
      .ant-empty {
        margin: 0 auto;
      }
      .course-item {
        position: relative;
        width: 282px;
        // height: 20px;
        margin-right: 24px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 6px 10px 0 #5f656926;
        cursor: pointer;
        transition: all 0.2s;
        .course-poster {
          width: 100%;
          height: 165px;
          border-radius: 8px 8px 0 0;
          display: block;
          margin-bottom: 8px;
        }
        .course-name {
          width: 90%;
          font-size: 15px;
          margin: 0 auto;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 8px;
          color: #545c63;
        }
        .course-view {
          width: 90%;
          margin: 0 auto;
          font-size: 13px;
          padding-bottom: 16px;
          // text-align: right;
          color: #9199a1;
        }
      }
      .course-item:nth-child(4n) {
        margin-right: 0;
      }
      .course-item:hover {
        box-shadow: 0 6px 10px 0 #5f656960;
        transform: translateY(-4px);
      }
    }
    .ant-pagination {
      text-align: center;
    }
  }
  .menu-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 100;
    background-color: rgb(53, 134, 240);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 16px;
    box-shadow: 0 8px 16px 0 #07111b1a;
    cursor: pointer;
  }
}
</style>
